export default function loopFix() {
  const swiper = this;

  swiper.emit('beforeLoopFix');

  const {
    activeindex,
    slides,
    loopedSlides,
    allowSlidePrev,
    allowSlideNext,
    snapGrid,
    rtlTranslate: rtl,
  } = swiper;
  let newindex;
  swiper.allowSlidePrev = true;
  swiper.allowSlideNext = true;

  const snapTranslate = -snapGrid[activeindex];
  const diff = snapTranslate - swiper.getTranslate();

  // Fix For Negative Oversliding
  if (activeindex < loopedSlides) {
    newindex = slides.length - loopedSlides * 3 + activeindex;
    newindex += loopedSlides;
    const slideChanged = swiper.slideTo(newindex, 0, false, true);
    if (slideChanged && diff !== 0) {
      swiper.setTranslate((rtl ? -swiper.translate : swiper.translate) - diff);
    }
  } else if (activeindex >= slides.length - loopedSlides) {
    // Fix For Positive Oversliding
    newindex = -slides.length + activeindex + loopedSlides;
    newindex += loopedSlides;
    const slideChanged = swiper.slideTo(newindex, 0, false, true);
    if (slideChanged && diff !== 0) {
      swiper.setTranslate((rtl ? -swiper.translate : swiper.translate) - diff);
    }
  }
  swiper.allowSlidePrev = allowSlidePrev;
  swiper.allowSlideNext = allowSlideNext;

  swiper.emit('loopFix');
}
