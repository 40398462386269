export default function prependSlide(slides) {
  const swiper = this;
  const { params, $wrapperEl, activeindex } = swiper;

  if (params.loop) {
    swiper.loopDestroy();
  }
  let newActiveindex = activeindex + 1;
  if (typeof slides === 'object' && 'length' in slides) {
    for (let i = 0; i < slides.length; i += 1) {
      if (slides[i]) $wrapperEl.prepend(slides[i]);
    }
    newActiveindex = activeindex + slides.length;
  } else {
    $wrapperEl.prepend(slides);
  }
  if (params.loop) {
    swiper.loopCreate();
  }
  if (!params.observer) {
    swiper.update();
  }
  swiper.slideTo(newActiveindex, 0, false);
}
