export default function removeSlide(slidesindexes) {
  const swiper = this;
  const { params, $wrapperEl, activeindex } = swiper;

  let activeindexBuffer = activeindex;
  if (params.loop) {
    activeindexBuffer -= swiper.loopedSlides;
    swiper.loopDestroy();
    swiper.slides = $wrapperEl.children(`.${params.slideClass}`);
  }
  let newActiveindex = activeindexBuffer;
  let indexToRemove;

  if (typeof slidesindexes === 'object' && 'length' in slidesindexes) {
    for (let i = 0; i < slidesindexes.length; i += 1) {
      indexToRemove = slidesindexes[i];
      if (swiper.slides[indexToRemove]) swiper.slides.eq(indexToRemove).remove();
      if (indexToRemove < newActiveindex) newActiveindex -= 1;
    }
    newActiveindex = Math.max(newActiveindex, 0);
  } else {
    indexToRemove = slidesindexes;
    if (swiper.slides[indexToRemove]) swiper.slides.eq(indexToRemove).remove();
    if (indexToRemove < newActiveindex) newActiveindex -= 1;
    newActiveindex = Math.max(newActiveindex, 0);
  }

  if (params.loop) {
    swiper.loopCreate();
  }

  if (!params.observer) {
    swiper.update();
  }
  if (params.loop) {
    swiper.slideTo(newActiveindex + swiper.loopedSlides, 0, false);
  } else {
    swiper.slideTo(newActiveindex, 0, false);
  }
}
