import { isObject } from '../../shared/utils.js';
import $ from '../../shared/dom.js';

export default function Thumb({ swiper, extendParams, on }) {
  extendParams({
    thumbs: {
      swiper: null,
      multipleActiveThumbs: true,
      autoScrollOffset: 0,
      slideThumbActiveClass: 'swiper-slide-thumb-active',
      thumbsContainerClass: 'swiper-thumbs',
    },
  });

  let initialized = false;
  let swiperCreated = false;

  swiper.thumbs = {
    swiper: null,
  };

  function onThumbClick() {
    const thumbsSwiper = swiper.thumbs.swiper;
    if (!thumbsSwiper || thumbsSwiper.destroyed) return;

    const clickedindex = thumbsSwiper.clickedindex;
    const clickedSlide = thumbsSwiper.clickedSlide;
    if (clickedSlide && $(clickedSlide).hasClass(swiper.params.thumbs.slideThumbActiveClass))
      return;
    if (typeof clickedindex === 'undefined' || clickedindex === null) return;
    let slideToindex;
    if (thumbsSwiper.params.loop) {
      slideToindex = parseInt($(thumbsSwiper.clickedSlide).attr('data-swiper-slide-index'), 10);
    } else {
      slideToindex = clickedindex;
    }
    if (swiper.params.loop) {
      let currentindex = swiper.activeindex;
      if (swiper.slides.eq(currentindex).hasClass(swiper.params.slideDuplicateClass)) {
        swiper.loopFix();
        // eslint-disable-next-line
        swiper._clientLeft = swiper.$wrapperEl[0].clientLeft;
        currentindex = swiper.activeindex;
      }
      const previndex = swiper.slides
        .eq(currentindex)
        .prevAll(`[data-swiper-slide-index="${slideToindex}"]`)
        .eq(0)
        .index();
      const nextindex = swiper.slides
        .eq(currentindex)
        .nextAll(`[data-swiper-slide-index="${slideToindex}"]`)
        .eq(0)
        .index();
      if (typeof previndex === 'undefined') slideToindex = nextindex;
      else if (typeof nextindex === 'undefined') slideToindex = previndex;
      else if (nextindex - currentindex < currentindex - previndex) slideToindex = nextindex;
      else slideToindex = previndex;
    }
    swiper.slideTo(slideToindex);
  }

  function init() {
    const { thumbs: thumbsParams } = swiper.params;
    if (initialized) return false;
    initialized = true;
    const SwiperClass = swiper.constructor;
    if (thumbsParams.swiper instanceof SwiperClass) {
      swiper.thumbs.swiper = thumbsParams.swiper;
      Object.assign(swiper.thumbs.swiper.originalParams, {
        watchSlidesProgress: true,
        slideToClickedSlide: false,
      });
      Object.assign(swiper.thumbs.swiper.params, {
        watchSlidesProgress: true,
        slideToClickedSlide: false,
      });
    } else if (isObject(thumbsParams.swiper)) {
      const thumbsSwiperParams = Object.assign({}, thumbsParams.swiper);
      Object.assign(thumbsSwiperParams, {
        watchSlidesProgress: true,
        slideToClickedSlide: false,
      });
      swiper.thumbs.swiper = new SwiperClass(thumbsSwiperParams);
      swiperCreated = true;
    }
    swiper.thumbs.swiper.$el.addClass(swiper.params.thumbs.thumbsContainerClass);
    swiper.thumbs.swiper.on('tap', onThumbClick);
    return true;
  }

  function update(initial) {
    const thumbsSwiper = swiper.thumbs.swiper;
    if (!thumbsSwiper || thumbsSwiper.destroyed) return;

    const slidesPerView =
      thumbsSwiper.params.slidesPerView === 'auto'
        ? thumbsSwiper.slidesPerViewDynamic()
        : thumbsSwiper.params.slidesPerView;

    // Activate thumbs
    let thumbsToActivate = 1;
    const thumbActiveClass = swiper.params.thumbs.slideThumbActiveClass;

    if (swiper.params.slidesPerView > 1 && !swiper.params.centeredSlides) {
      thumbsToActivate = swiper.params.slidesPerView;
    }

    if (!swiper.params.thumbs.multipleActiveThumbs) {
      thumbsToActivate = 1;
    }

    thumbsToActivate = Math.floor(thumbsToActivate);

    thumbsSwiper.slides.removeClass(thumbActiveClass);
    if (
      thumbsSwiper.params.loop ||
      (thumbsSwiper.params.virtual && thumbsSwiper.params.virtual.enabled)
    ) {
      for (let i = 0; i < thumbsToActivate; i += 1) {
        thumbsSwiper.$wrapperEl
          .children(`[data-swiper-slide-index="${swiper.realindex + i}"]`)
          .addClass(thumbActiveClass);
      }
    } else {
      for (let i = 0; i < thumbsToActivate; i += 1) {
        thumbsSwiper.slides.eq(swiper.realindex + i).addClass(thumbActiveClass);
      }
    }

    const autoScrollOffset = swiper.params.thumbs.autoScrollOffset;
    const useOffset = autoScrollOffset && !thumbsSwiper.params.loop;
    if (swiper.realindex !== thumbsSwiper.realindex || useOffset) {
      let currentThumbsindex = thumbsSwiper.activeindex;
      let newThumbsindex;
      let direction;
      if (thumbsSwiper.params.loop) {
        if (
          thumbsSwiper.slides
            .eq(currentThumbsindex)
            .hasClass(thumbsSwiper.params.slideDuplicateClass)
        ) {
          thumbsSwiper.loopFix();
          // eslint-disable-next-line
          thumbsSwiper._clientLeft = thumbsSwiper.$wrapperEl[0].clientLeft;
          currentThumbsindex = thumbsSwiper.activeindex;
        }
        // Find actual thumbs index to slide to
        const prevThumbsindex = thumbsSwiper.slides
          .eq(currentThumbsindex)
          .prevAll(`[data-swiper-slide-index="${swiper.realindex}"]`)
          .eq(0)
          .index();
        const nextThumbsindex = thumbsSwiper.slides
          .eq(currentThumbsindex)
          .nextAll(`[data-swiper-slide-index="${swiper.realindex}"]`)
          .eq(0)
          .index();
        if (typeof prevThumbsindex === 'undefined') {
          newThumbsindex = nextThumbsindex;
        } else if (typeof nextThumbsindex === 'undefined') {
          newThumbsindex = prevThumbsindex;
        } else if (nextThumbsindex - currentThumbsindex === currentThumbsindex - prevThumbsindex) {
          newThumbsindex =
            thumbsSwiper.params.slidesPerGroup > 1 ? nextThumbsindex : currentThumbsindex;
        } else if (nextThumbsindex - currentThumbsindex < currentThumbsindex - prevThumbsindex) {
          newThumbsindex = nextThumbsindex;
        } else {
          newThumbsindex = prevThumbsindex;
        }
        direction = swiper.activeindex > swiper.previousindex ? 'next' : 'prev';
      } else {
        newThumbsindex = swiper.realindex;
        direction = newThumbsindex > swiper.previousindex ? 'next' : 'prev';
      }
      if (useOffset) {
        newThumbsindex += direction === 'next' ? autoScrollOffset : -1 * autoScrollOffset;
      }

      if (
        thumbsSwiper.visibleSlidesindexes &&
        thumbsSwiper.visibleSlidesindexes.indexOf(newThumbsindex) < 0
      ) {
        if (thumbsSwiper.params.centeredSlides) {
          if (newThumbsindex > currentThumbsindex) {
            newThumbsindex = newThumbsindex - Math.floor(slidesPerView / 2) + 1;
          } else {
            newThumbsindex = newThumbsindex + Math.floor(slidesPerView / 2) - 1;
          }
        } else if (
          newThumbsindex > currentThumbsindex &&
          thumbsSwiper.params.slidesPerGroup === 1
        ) {
          // newThumbsindex = newThumbsindex - slidesPerView + 1;
        }
        thumbsSwiper.slideTo(newThumbsindex, initial ? 0 : undefined);
      }
    }
  }

  on('beforeInit', () => {
    const { thumbs } = swiper.params;
    if (!thumbs || !thumbs.swiper) return;
    init();
    update(true);
  });
  on('slideChange update resize observerUpdate', () => {
    update();
  });
  on('setTransition', (_s, duration) => {
    const thumbsSwiper = swiper.thumbs.swiper;
    if (!thumbsSwiper || thumbsSwiper.destroyed) return;
    thumbsSwiper.setTransition(duration);
  });
  on('beforeDestroy', () => {
    const thumbsSwiper = swiper.thumbs.swiper;
    if (!thumbsSwiper || thumbsSwiper.destroyed) return;
    if (swiperCreated) {
      thumbsSwiper.destroy();
    }
  });

  Object.assign(swiper.thumbs, {
    init,
    update,
  });
}
