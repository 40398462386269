export default function updateActiveindex(newActiveindex) {
  const swiper = this;
  const translate = swiper.rtlTranslate ? swiper.translate : -swiper.translate;
  const {
    slidesGrid,
    snapGrid,
    params,
    activeindex: previousindex,
    realindex: previousRealindex,
    snapindex: previousSnapindex,
  } = swiper;
  let activeindex = newActiveindex;
  let snapindex;
  if (typeof activeindex === 'undefined') {
    for (let i = 0; i < slidesGrid.length; i += 1) {
      if (typeof slidesGrid[i + 1] !== 'undefined') {
        if (
          translate >= slidesGrid[i] &&
          translate < slidesGrid[i + 1] - (slidesGrid[i + 1] - slidesGrid[i]) / 2
        ) {
          activeindex = i;
        } else if (translate >= slidesGrid[i] && translate < slidesGrid[i + 1]) {
          activeindex = i + 1;
        }
      } else if (translate >= slidesGrid[i]) {
        activeindex = i;
      }
    }
    // Normalize slideindex
    if (params.normalizeSlideindex) {
      if (activeindex < 0 || typeof activeindex === 'undefined') activeindex = 0;
    }
  }
  if (snapGrid.indexOf(translate) >= 0) {
    snapindex = snapGrid.indexOf(translate);
  } else {
    const skip = Math.min(params.slidesPerGroupSkip, activeindex);
    snapindex = skip + Math.floor((activeindex - skip) / params.slidesPerGroup);
  }
  if (snapindex >= snapGrid.length) snapindex = snapGrid.length - 1;
  if (activeindex === previousindex) {
    if (snapindex !== previousSnapindex) {
      swiper.snapindex = snapindex;
      swiper.emit('snapindexChange');
    }
    return;
  }

  // Get real index
  const realindex = parseInt(
    swiper.slides.eq(activeindex).attr('data-swiper-slide-index') || activeindex,
    10,
  );

  Object.assign(swiper, {
    snapindex,
    realindex,
    previousindex,
    activeindex,
  });
  swiper.emit('activeindexChange');
  swiper.emit('snapindexChange');
  if (previousRealindex !== realindex) {
    swiper.emit('realindexChange');
  }
  if (swiper.initialized || swiper.params.runCallbacksOnInit) {
    swiper.emit('slideChange');
  }
}
